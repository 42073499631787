import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div className="grid-item">
      <div className="grid-content">
        <div id="intro">
          I am a full-stack software engineer specializing in .Net technologies, with front-end experience in web and mobile applications.
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
